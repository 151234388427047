@font-face {
    font-family: "Neulis Sans";
    src: url("../fonts/Neulis-Sans-Regular.otf") format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: "Neulis Sans";
    src: url("../fonts/Neulis-Sans-Bold.otf") format("opentype");
    font-weight: 700;
}

@font-face {
    font-family: "Neulis Sans";
    src: url("../fonts/Neulis-Sans-Medium.otf") format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: "Neulis Sans";
    src: url("../fonts/Neulis-Sans-SemiBold.otf") format("opentype");
    font-weight: 600;
}

@font-face {
    font-family: "Neulis Sans";
    src: url("../fonts/Neulis-Sans-Light.otf") format("opentype");
    font-weight: 300;
}

@import "bootstrap-icons/font/bootstrap-icons.css";


$primary: #0EA5A8;
$warning: #F8BF00;
$danger: #D80000;
$white: #fff;
$grey: #dfe3e8;
$light-grey: #f2f6fa;
$light: $light-grey;
$dark: #121212;
$secondary-color: #5f5f5f;

// global font
$font-family-base: "Neulis Sans", sans-serif;
$font-size-base: 1.125rem;
$body-color: $dark;

// link
$link-decoration: none;
$link-hover-decoration: none;
$link-color: $dark;
$link-hover-color: $primary;

// border
$border-radius-xxl: 1.563rem;

// btn
$input-btn-padding-y-lg: 1rem;
$input-btn-padding-x-lg: 1.375rem;
$input-btn-font-size-lg: 1.25rem;
$input-border-radius: 10px;
$btn-border-radius: 10px;
$btn-border-radius-sm: 8px;
$btn-border-radius-lg: 10px;
$btn-disabled-bg: #A0A0A0;

// accordion
$accordion-button-active-bg: $white;
$accordion-button-active-color: $primary;
$accordion-button-focus-box-shadow: none;
$accordion-icon-color: $white;
$accordion-icon-active-color: $primary;
$accordion-border-width: 0;
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$accordion-icon-active-color}' class='bi bi-dash-lg' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8'/></svg>");
$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-plus-lg' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2'/></svg>");

// card
$card-border-color: $grey;
$card-border-radius: 1rem;

// modal
$modal-content-border-radius: 1rem;

// input group addon
$input-group-addon-bg: $white;

// form label
$form-label-margin-bottom: .75rem;
$form-label-font-size: .875rem;
$form-label-font-weight: 500;

@import "bootstrap/scss/bootstrap";

// bootstrap overide

html {
    scroll-behavior: smooth;
  }
  
.accordion {
    .accordion-item {
        border: 1px solid $grey !important;
        border-radius: 1rem !important;
        overflow: hidden;
    }

    .accordion-button {
        font-size: 1.25rem;
        font-weight: 700;
    }

    .accordion-body {
        text-align: left;
        padding-top: 0;
    }

    .accordion-item {
        margin-bottom: 1rem;
    }
}

.btn {
    font-weight: 600;
}

.btn-primary {
    color: $white;
    &:hover, &:active {
        color: $white !important
    }
}

hr {
    color: $grey;
    opacity: 1;
    margin-top: 20px;
    margin-bottom: 20px;
}

li:not(.nav-item) {
    margin-bottom: 12px;
}

.form-control, .form-select {
    padding: 11px;
}

.form-control-lg {
    font-size: 1.125rem;
    padding: 1rem 1.375rem;
}

.input-group {
    input {
        border-right: 0;
    }
    .input-group-text {
        border-left: 0;
    }
}

.fs-7 {
    font-size: 1rem;
}

.fs-8 {
    font-size: .875rem;
}

.fs-9 {
    font-size: .75rem;
}

.hero-section {
    color: $white;
    background-image: url("../images/find-real-estate-expert.jpg");
    background-size: cover;
    border-radius: 25px;
    min-height: calc(100vh - 8rem);
}

.text-xxl {
    font-size: 4rem;
    font-weight: bold;
}

.client-section {
    img {
        height: 25px;
    }
}

.nav-item {
    @extend .fs-7;
}

.grey-section {
    border-radius: 25px;
    background: $light-grey;
}

.expert-indicator {
    display: flex;
    justify-content: center;
    gap: 6px;

    .dot, .pill {
        border-radius: 10px;
        background: $dark;
        cursor: pointer;
    }

    .dot {
        width: 8px;
        height: 8px;
        opacity: 0.1;
    }

    .pill {
        width: 25px;
        height: 8px;
    }
}

.testi-navigation {
    display: flex;
    gap: 12px;
    justify-content: end;
}

.btn-navigation {
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid $grey;

    i {
        font-size: 2rem;
        font-weight: 600;
    }
}

.footer {
    position: relative;

    &::before {
        position: absolute;
        content: "";
        z-index: -1;
        top: 6rem;
        width: 100%;
        height: calc(100% - 6rem);
        background: $light-grey;
        border-radius: 25px;
    }

    li {
        margin-bottom: 18px;
    }
}

.work-step {
    font-size: 1.563rem;
    background: $light-grey;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin-bottom: 1.5rem;
    color: $primary;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}

.booking-card {
    background: #f9f9f9; // Change this to your chosen color
    .card-body {
        max-height: 332px;
        overflow-y: auto;
    }

    .card-footer {
        background: $white; // Keep this or change to a darker shade for contrast
    }
}

.select-row {
    display: flex;
    flex-wrap: wrap;

    &.hours {
        span {
            width: 100px;
        }
    }

    span {
        width: 20%;
        padding: 4px;
    }

    .btn {
        font-size: 14px;
        padding-left: 4px;
        padding-right: 4px;
        width: 100%;
        border: solid;
    }
}

.modal-close-btn {
    position: absolute;
    font-size: 1.5rem;
    right: 0;
}

.detail-confirmation {
    border-right: 1px solid $grey;
}

.detail-payment {
    min-height: calc(100vh - 162px);
}

.btn-play {
    height: 60px;
    width: 60px;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    animation: glowing 2s infinite;

    i {
        font-size: 36px;
    }
}

.expert-step-section {
    .grey-section {
        padding: 94px 78px;
    }
}

.hero-about-us-section {
    position: relative;
    .container {
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
}

.legal-section {
    padding-left: 15rem;
    padding-right: 15rem;
    p, li {
        color: $secondary-color;
    }

    li::marker {
        color: $black;
    }

    ul {
        padding-left: 1rem;
    }

    p {
        margin-bottom: 25px;
    }

    strong {
        color: $black;
    }
}

.main-about-us {
    margin-top: -80px;
    .grey-section {
        padding-top: 200px;
        padding-bottom: 120px;
    }
}

.input-complete-phone {
    position: relative;

    select {
        position: absolute;
        width: 60px;
        padding: 8px;
        background-color: #f2f6fa;
        top: 4.5px;
        left: 4.5px;
        border: none;
    }

    input {
        padding-left: 70px;
    }
}

// Mobile only
@include media-breakpoint-down(sm) {
    .btn-lg {
        padding: 13px 18px;
        font-size: 1.125rem;
    }

    .client-section {
        flex-wrap: wrap;
        gap: 32px;
        img {
            height: 17px;
        }
    }

    .container {
        --bs-gutter-x: 2rem;
    }

    .testi-avatar {
        img {
            width: 100px;
        }
    }

    .testi-navigation {
        justify-content: center;
    }

    .btn-navigation {
        height: 50px;
        width: 50px;

        i {
            font-size: 1.5rem;
            font-weight: 600;
        }
    }

    .accordion {
        .accordion-button {
            font-size: 1.125rem;
        }

        .accordion-body {
            font-size: 1rem;
        }
    }

    .select-row {
        span {
            width: 25%;
            padding: 4px;
        }
    }

    .expert-step-section {
        .grey-section {
            margin-bottom: 12px;
        }
    }

    .hero-about-us-section {
        .container {
            position: static;
            transform: none;
        }
    }

    .main-about-us {
        margin-top: 42px;
        .grey-section {
            padding-top: 42px;
            padding-bottom: 42px;
        }
    }

}

// Mobile and Tablet portrait
@include media-breakpoint-down(lg) {

    .drawer-mobile {
        text-align: center;

        .modal-content {
            padding: 0 20px;
            border-radius: 0;
            height: auto;
        }

        .modal-dialog {
            margin: 0;
        }

        li {
            font-size: 22px;
            margin-bottom: 16px;
        }

        .btn {
            padding: 13px;
            width: 100%;
            border-radius: 12px;
            margin-top: 16px;
        }
    }

    .hero-section {
        background-image: url("../images/find-real-estate-expert-mobile.jpg");
        background-position: center;
    }

    .text-xxl {
        font-size: calc(1.5rem + 3.5vw);
        line-height: 1;
    }

    .detail-confirmation {
        border-right: none;
        border-bottom: 1px solid $grey;
    }

    .expert-step-section {
        .grey-section {
            padding: 16px;
        }
    }

    .legal-section {
        padding: unset;
    }
}

// tablet only
@include media-breakpoint-only(md) {
    .text-xxl {
        font-size: 2.75rem;
    }
 }

@keyframes glowing {
    0% {
        box-shadow: 0 0 1px 10px rgba(18, 18, 18, 0.2)
    }

    50% {
        box-shadow: 0px 0px 2.5px 0 transparent
    }

    100% {
        box-shadow: 0 0 1px 10px rgba(18, 18, 18, 0.2)
    }
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .popup {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  
  .popup button {
    margin-top: 10px;
  }

  /* In your CSS file */
.important-style {
    font-size: 17px !important;
  }

/* Style for inactive carousel indicators */
.carousel-indicators button {
    background-color: rgb(0, 0, 0)!important; /* Set this to the desired color for inactive indicators */
    opacity: 0.2!important; /* Optional: to make inactive indicators less prominent */
}

/* Style for active carousel indicator */
.carousel-indicators .active {
    background-color: black; /* Set this to the desired color for active indicator */
    opacity: 1!important; /* Ensure the active indicator is fully opaque */
}

.carousel-control-prev,
.carousel-control-next {
    top: 40%;
    transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  opacity: 1;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 15px;
  height: 15px;
  background-size: 100%, 100%;
  background-color: white;
}


.carousel-control-next-icon{
    background-color: black;
}

.carousel-control-prev-icon{
    background-color: black;
}   
/* For screens smaller than 768px */
@media (min-width: 767px) {
    .carousel-control-prev {
      left: -35px;
    }
  
    .carousel-control-next {
      right: -35px;
    }
  }
  
  /* For screens 768px and wider, hide the controls */
  @media (max-width: 768px) {
    .carousel-control-prev,
    .carousel-control-next {
      display: none;
    }
  }

  #CarouselReviewID .carousel-control-prev{
    display: none;
   }

   #CarouselReviewID .carousel-control-next{
    display: none;
   }

// Video
    video {
        width: 80%;
        height: auto;
    }
    .video-border{
        border-radius: 20px;
        cursor: pointer;
     }
     
     .video-container {
        position: relative;
      }
      
      .play-button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100px;
        height: 100px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        cursor: pointer;
      }
      
      .play-button::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-30%, -50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 0 20px 30px;
        border-color: transparent transparent transparent white;
      }

      /* Target the form-control input inside react-tel-input */
    .react-tel-input .form-control {
        border: 0px !important;  
        width: inherit !important;
    }
    .flag-dropdown{
        border: 0px !important;  
        background-color: transparent!important;
        
    }
    .resendcode{
        margin-top: -24px !important;
    }
    .error-message{
        color: #ff0000; /* Red color for errors */
        background-color: #ffe0e0; /* Light red background */
        border: 1px solid #ff0000;
        padding: 10px;
        margin: 10px 0;
        border-radius: 5px;
        font-size: 14px;
        text-align: center;
    }
    .success-message {
        color: #155724; /* Green color for success */
        background-color: #d4edda; /* Light green background */
        border: 1px solid #c3e6cb;
        padding: 10px;
        margin: 10px 0;
        border-radius: 5px;
        font-size: 14px;
    }
    .otp-input {
        font-size: 24px;  /* Larger font size for better visibility */
        letter-spacing: 15px; /* Space out the characters */
        width: 145px;  /* Width enough to fit 4 spaced characters */
        text-align: center;
    }
    
    .otp-input::placeholder {
        letter-spacing: 20px; /* Match the letter-spacing */
        color: #bbb; /* Lighter color for placeholder */
    }
    .code-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .center{
        text-align: center;
    }
    .payment{
        width: 100%!important;
    }
    .slot{
        padding-left: 5px!important;
        padding-right: 5px!important;
    }

    .but_call{
        width: 100px!important;
    }

    /* Only display on mobile (max-width 768px) */
    .mobile-only {
        display: none;
    }

    @media (max-width: 768px) {
        .mobile-only {
            display: inline-block; /* or flex, if needed */
        }
    }
    /*Admin*/

    .app__brand__text {
        font-size: 2rem;
        font-weight: 700;
        color: #5a8dee;
        margin-left: 0.5rem;
      }
      
      /* Sidebar toggle button starts */
      .outer__circle {
        position: relative;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        background-color: #5f97ef;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      .outer__circle::after {
        position: absolute;
        top: 0.225rem;
        left: 0.215rem;
        content: "";
        width: 1.1rem;
        height: 1.1rem;
        border-radius: 50%;
        background-color: #fff;
      }
      
      .inner__circle {
        position: relative;
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 50%;
        background-color: #5f97ef;
        z-index: 100;
      }
      
      .inner__circle::after {
        position: absolute;
        top: 0.125rem;
        left: 0.15rem;
        content: "";
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: #fff;
      }
      /* Sidebar toggle button ends */

      .justify-text {
        text-align: justify;    /* Justify the text */
      }

